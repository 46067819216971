import "./register.css";
import { Home } from "../home/home";
import React, { useEffect } from "react";
import { backDrop, ServerDate, showAgreementModal, useForceUpdate } from "../../services/misc.functions";
import { SubmitHandler, useForm } from "react-hook-form";
import { ApiService } from "../../services/http.service";
import { env, redirectionErrorCodes } from "../../constants/global.vars";
import { useNavigate } from "react-router-dom";
import paths from "../../paths";

import { modalServiceSubject } from "../../services/modal.service";
import { userLogin, userValidation } from "../../services/auth.service";
import { auth, authSubject } from "../../store/auth.store";
import ReactInputMask from "react-input-mask";
import EyeIcon from "../../assets/feather/eye.svg";
import EyeOffIcon from "../../assets/feather/eye-off.svg";
import FormError from "../../shared/form.error";
import { professions } from "../../constants/basic.constants";

let validationControl: any;
const years: any = [];
const days: any = [];
const months = [
  { val: "01", name: "01" },
  { val: "02", name: "Şubat" },
  { val: "03", name: "Mart" },
  { val: "04", name: "Nisan" },
  { val: "05", name: "Mayıs" },
  { val: "06", name: "Haziran" },
  { val: "07", name: "Temmuz" },
  { val: "08", name: "Ağustos" },
  { val: "09", name: "Eylül" },
  { val: "10", name: "Ekim" },
  { val: "11", name: "Kasım" },
  { val: "12", name: "Aralık" },
];
let today = ServerDate();
let yy = today.getFullYear() - 18;
for (let i = yy; i >= yy - 70; i--) {
  years.push(i);
}
for (let j = 1; j < 32; j++) {
  days.push(j < 10 ? "0" + j : j.toString());
}

export function Register() {
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    backDrop(true);
    return () => {
      backDrop(false);
    };
  }, []);

  useEffect(() => {
    if (auth.member?.id) {
      navigate(paths.home);
    }
  }, [auth.member?.id]);

  useEffect(() => {
    let auth_subscriber = authSubject.subscribe((res: any) => {
      forceUpdate();
    });
    return () => {
      auth_subscriber.unsubscribe();
    };
  }, []);

  const onSubmit: SubmitHandler<any> = (payload: any) => {
    auth.userValidation = false;
    const api = new ApiService();
    payload.mobile = payload.mobile.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "");
    payload.birthday = payload.birthday_year + "-" + payload.birthday_month + "-" + payload.birthday_day;
    let loginButton: any = document.getElementById("id_btnRegister");
    if (loginButton) {
      loginButton.disabled = true;
    }

    if (payload.password !== payload.password_confirm) {
      modalServiceSubject.next({
        case: "danger",
        title: "Parolanızı kontrol ediniz!",
        content: "Girdiğiniz parola ve doğrulama birbiri ile eşleşmiyor, lütfen parolanızı kontrol ediniz.",
        timeout: 5000,
        confirm: {
          cancel: {
            label: "Tamam",
            class: "btn",
          },
        },
      });
      if (loginButton) {
        loginButton.disabled = false;
      }
    } else {
      api
        .start("post", env.api + "/member/api/register/", payload, false)
        .then((res: any) => {
          if (res.status) {
            userValidation(payload);
            validationControl = setInterval(() => {
              if (auth.userValidation === true) {
                clearInterval(validationControl);
                userLogin({
                  user: payload.tc_id,
                  password: payload.password,
                }).then((response: any) => {
                  if (response.status) {
                    navigate(paths.home.url);
                  }
                });
              }
            }, 100);
          } else {
            // fixme bu kısımda bir log atılmalı
            modalServiceSubject.next({
              case: "danger",
              title: "Hata",
              width: 380,
              content: `${res.message}`,
              confirm: {
                cancel: {
                  label: "Tamam",
                  class: "btn-danger",
                },
                sure: redirectionErrorCodes.includes(res.error)
                  ? {
                      label: "Giriş yap",
                      action: () => {
                        navigate(paths.login);
                      },
                    }
                  : null,
              },
            });
          }
        })
        .catch((e: any) => {
          // console.log(e)
        })
        .finally(() => {
          if (loginButton) {
            loginButton.disabled = false;
          }
        });
    }
  };

  return (
    <>
      <Home></Home>
      <div className="register-widget">
        <div className={"modal-header"}>
          <h5 className="w-100 text-center text-bolder align-self-center mb-0 text-white">Üye Ol</h5>
          <div onClick={() => navigate(paths.home.url)}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
        </div>

        <div className="modal-body register-form py-4">
          <RegisterForm onSubmit={onSubmit} />
          <button className="button sm tertiary outlined mt-3 w-100" onClick={() => navigate(paths.login.url)}>
            <p className="text-small text-bold text-center">Zaten Üyeyim</p>
          </button>
        </div>
      </div>
    </>
  );
}

export function RegisterForm(props: any) {
  const navigate = useNavigate();
  const onSubmit: any = props.onSubmit;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isPasswordVisible = () => {
    let password: any = document.getElementById("id_password");
    let eyeIcon: any = document.getElementById("eye-icon");

    if (password.type === "password") {
      password.type = "text";
      eyeIcon.src = EyeIcon;
    } else {
      password.type = "password";
      eyeIcon.src = EyeOffIcon;
    }
  };

  const isPasswordVisible2 = () => {
    let password: any = document.getElementById("id_password2");
    let eyeIcon: any = document.getElementById("eye-icon2");

    if (password.type === "password") {
      password.type = "text";
      eyeIcon.src = EyeIcon;
    } else {
      password.type = "password";
      eyeIcon.src = EyeOffIcon;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="w-100">
          <label className={"text-primary text-small mb-1"}>T.C. Kimlik Numarası</label>
          <input type="tel" placeholder="" {...register("tc_id", { required: true })} />
          {errors.tc_id && <p className="invalid-feedback">11 Haneli TC kimlik numaranızı girin</p>}
        </div>
      </div>
      <div className="form-row">
        <div className="w-50">
          <label className={"text-primary text-small mb-1"}>Ad</label>
          <input type="text" placeholder="" {...register("first_name", { required: true })} />
          {errors.first_name && <p className="invalid-feedback">Lütfen adınızı girin</p>}
        </div>
        <div className="w-50">
          <label className={"text-primary text-small mb-1"}>Soyad</label>
          <input type="text" placeholder="" {...register("last_name", { required: true })} />
          {errors.last_name && <p className="invalid-feedback">Lütfen soyadınızı girin</p>}
        </div>
      </div>

      <div className="form-row">
        <div className="w-100">
          <label htmlFor="id_birthday" className={"text-primary text-small mb-1"}>
            Doğum Tarihi
          </label>
          <div className="row">
            <div className="col d-flex flex-column p-0 pe-1">
              <select
                className={`form-select register-select`}
                {...register("birthday_day", { required: true })}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Gün
                </option>
                {days.map((x: any) => {
                  return (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  );
                })}
              </select>
              {errors.birthday_day && errors.birthday_day.type === "required" && (
                <span className="text-validation">Doğum tarihi gereklidir.</span>
              )}
            </div>
            <div className="col d-flex flex-column p-0 pe-1">
              <select
                className={`form-select register-select`}
                {...register("birthday_month", { required: true })}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Ay
                </option>
                {months.map((x: any) => (
                  <option value={x.val} key={x.val}>
                    {x.val}
                  </option>
                ))}
              </select>
              {errors.birthday_month && errors.birthday_month.type === "required" && (
                <span className="text-validation">Doğum tarihi gereklidir.</span>
              )}
            </div>
            <div className="col d-flex flex-column p-0">
              <select
                className={`form-select register-select`}
                {...register("birthday_year", { required: true })}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Yıl
                </option>
                {years.map((x: any) => (
                  <option value={x} key={x}>
                    {x}
                  </option>
                ))}
              </select>
              {errors.birthday_year && errors.birthday_year.type === "required" && (
                <span className="text-validation">Doğum tarihi gereklidir.</span>
              )}
            </div>
          </div>
          {/*<input type="text" onFocus={(e:any)=>{ e.target.type='date' }} placeholder="DOĞUM TARİHİ" {...register("birthday", { required: true })} />*/}
          {/*{errors.birthday && <p className="invalid-feedback">Doğum tarihinizi Gün/Ay/Yıl şeklinde girin</p>}*/}
        </div>
      </div>
      <div className="form-row">
        <div className="w-100">
          <label className={"text-primary text-small mb-1"}>E-Posta Adresi</label>
          <input type="email" placeholder="" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
          {errors.email && <p className="invalid-feedback">Geçerli e-posta adresinizi girin</p>}
        </div>
      </div>
      <div className="form-row">
        <div className="w-100">
          <label className={"text-primary text-small mb-1"}>Cep Telefonu</label>
          <ReactInputMask
            mask="(599) 999 99 99"
            className="form-control"
            type={"tel"}
            placeholder={"5XX XXX XX XX"}
            {...register<any>("mobile", { required: true })}
          />

          {errors.mobile && <p className="invalid-feedback">Cep telefonu numaranızı girin</p>}
        </div>
      </div>

      <div className="form-row">
        <div className="w-100">
          <select
            className="form-select register-select w-100"
            defaultValue=""
            {...register("profession", { required: "Meslek bilgisi gereklidir." })}
          >
            <option value="" disabled>
              Meslek Seçiniz
            </option>
            {professions.map((x: any) => (
              <option value={x.id} key={x.name}>
                {x.name}
              </option>
            ))}
          </select>
          {errors.profession && <p className="invalid-feedback">Meslek bilgisi gereklidir.</p>}
        </div>
      </div>

      <div className="form-row">
        <div className="w-100">
          <label className={"text-primary text-small mb-1"}>Şifre</label>
          <input
            className="form-control"
            id="id_password"
            type="password"
            placeholder=""
            {...register("password", { required: true })}
          />
          <div className="cursor-pointer password-eye" onClick={() => isPasswordVisible()}>
            <img id="eye-icon" height={16} width={16} src={EyeOffIcon} alt="Toggle visibility" />
          </div>
        </div>
        {errors.password && <p className="invalid-feedback">En az 8 haneli şifrenizi belirleyin</p>}
      </div>

      <div className="form-row">
        <div className="w-100">
          <label className={"text-primary text-small mb-1"}>Şifre Tekrar</label>
          <input
            className="form-control"
            id="id_password2"
            type="password"
            placeholder=""
            {...register("password_confirm", { required: true })}
          />
          <div className="cursor-pointer password-eye" onClick={() => isPasswordVisible2()}>
            <img id="eye-icon2" height={16} width={16} src={EyeOffIcon} alt="Toggle visibility" />
          </div>
        </div>
        {errors.password && <p className="invalid-feedback">En az 8 haneli şifrenizi belirleyin</p>}
      </div>
      <div className="mt-2">
        <span className="text-xsmall me-2">Şifre kuralları:</span>
        <span className="text-xsmall password-validator-item">rakam olmalı</span>
        <span className="text-xsmall password-validator-item">harf olmalı</span>
        <span className="text-xsmall password-validator-item">en az 8 karakter</span>
        <span className="text-xsmall password-validator-item">TCKN olmamalı</span>
        <span className="text-xsmall password-validator-item">doğum yılı olmamalı</span>
        <span className="text-xsmall password-validator-item">isim-soyisim olmamalı</span>
        <span className="text-xsmall password-validator-item">3 kez tekrar etmeyen sayı</span>
      </div>

      {/* <div className="form-row">
              <div className="w-100">
                <div className="d-flex flex-row align-items-center input gap-1 bg-white border-secondary-subtle ">
                  <input
                    className="border border-0 bg-transparent"
                    id="id_password2"
                    type="password"
                    placeholder="Şifreniz (Tekrar)"
                    {...register("password_confirm", { required: true })}
                  />
                  <div className="cursor-pointer password-eye float-right mr-2" onClick={() => isPasswordVisible2()}>
                    <img id="eye-icon2" height={16} width={16} src={EyeOffIcon} />
                  </div>
                </div>
                {errors.password_confirm && <p className="invalid-feedback">En az 8 haneli şifrenizi tekrar girin</p>}
              </div>
            </div> */}

      {/*<div className="form-row">*/}
      {/*    <div className="w-100">*/}
      {/*        <label className={`checkbox-text d-block ${isMobile ? 'text-light' : ''}`}>*/}
      {/*            Kişisel verilerin işlenmesine dair <a*/}
      {/*            className="fw-medium"*/}
      {/*            onClick={() => {*/}
      {/*                showAgreementModal("aydinlatma-metni");*/}
      {/*            }}>Aydınlatma Metnini</a> okuyunuz.*/}
      {/*        </label>*/}
      {/*    </div>*/}
      {/*</div>*/}

      <div className="mb-3">
        <span className="text-xsmall">Kişisel verilerin işlenmesine dair </span>
        <span
          className="text-xsmall text-primary fw-medium text-decoration-underline"
          onClick={() => {
            showAgreementModal("aydinlatma");
          }}
        >
          Aydınlatma Metni
        </span>
        <span className="text-xsmall ">'ni okuyunuz.</span>
      </div>

      <div className=" mb-3">
        <div className="checkbox-input-wrapper">
          <input id="Üyelik Sözleşmesi" className="checkbox-input" type="checkbox" name="uyelikSozlesmesi" />
          <label htmlFor="Üyelik Sözleşmesi"></label>

          <div>
            <span
              onClick={() => {
                showAgreementModal("kullanim-sartlari");
              }}
              className="text-xsmall text-primary fw-medium  text-decoration-underline"
            >
              Üyelik Sözleşmesi
            </span>
            <span className="text-xsmall">'ni okudum ve kabul ediyorum.</span>
          </div>
        </div>
        {errors.terms && <FormError error={"Üyelik sözleşmesini kabul etmeden devam edemezsiniz."} />}
      </div>

      <div className=" mb-3">
        <div className="checkbox-input-wrapper">
          <input id="kvkk" className="checkbox-input" type="checkbox" name="kvkk" />
          <label htmlFor="kvkk"></label>
          <div>
            <span className="text-xsmall">Kişisel Verilerin işlenmesine ilişkin </span>
            <span
              className="text-xsmall text-primary fw-medium text-decoration-underline"
              onClick={() => {
                showAgreementModal("fpaydinlatma");
              }}
            >
              Açık Rıza Metni
            </span>
            <span className="text-xsmall">'ni okudum ve kabul ediyorum.</span>
          </div>
        </div>
        {errors.privacy && <FormError error={"Açıkrıza metnini kabul etmeden devam edemezsiniz."} />}
      </div>

      <div className=" mb-3">
        <div className="checkbox-input-wrapper">
          <input id="kampanyalar" className="checkbox-input" type="checkbox" name="kampanyalar" />
          <label htmlFor="kampanyalar"></label>
          <div>
            <span className="text-xsmall">
              Hediyeler, kampanyalar ve bana özel fırsatlardan haberdar olmak amacıyla{" "}
            </span>
            <span
              className="text-xsmall text-primary fw-medium text-decoration-underline"
              onClick={() => showAgreementModal("ticari-elektronik-ileti-aydinlatma-metni")}
            >
              Ticari Elektronik İleti Aydınlatma Metni
            </span>
            <span className="text-xsmall">
              {" "}
              çerçevesinde iletişim verilerimin işlenmesini, kullanılması ve saklanmasını kabul ediyorum.
            </span>
          </div>
        </div>
      </div>

      <button className="button sm secondary filled mt-3 w-100 " type={"submit"}>
        <p className="text-small text-bold text-center">Devam</p>
      </button>
    </form>
  );
}
