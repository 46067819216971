import React, {useState} from "react";
import {modalServiceSubject} from "./modal.service";
import {toast} from "react-toastify";
import {
    agreements,
    env,
    errorLogStyle,
    flatPages,
    news,
    resultsUrlPrefix,
    successLogStyle,
} from "../constants/global.vars";
import {ApiService} from "./http.service";
import {Storage} from "./localstorege.service";
import {
    agreementSubject,
    ConnectionStatus,
    flatPageSubject,
    homeHippodrome,
    newsSubject,
    tipstersData,
    todayResults,
    todayResultsSubject,
} from "../store/misc.store";
import iconFather from "../assets/feather/father-at-yarisi.svg";
import {wsProvider} from "../App";
import {professions} from "../constants/basic.constants";
import {wssProvider} from "./websocket.service";

export let ServerTimeDifference: number = 0;
let resultsInterval: any = null;

export function getServerTime() {
    let browserDate = new Date();

    // api.start('get', env.api+'/misc/api/get-current-date/?v=' + Math.random().toString(), null, false)
    //   .then((response: any) => {
    //     if (response.status) {
    //       let serverDate = new Date(response.data.currentDate)
    //       // @ts-ignore
    //       ServerTimeDifference = (serverDate.getTime() + (serverDate.getTimezoneOffset() * 60000)) - browserDate.getTime() + (3600000 * 3);
    //       console.log('%cBrowser timedelta: ' + ServerTimeDifference + ' saniye',warninLogStyle);
    //       // console.log("%cTarayıcı Zamanı: "+browserDate, warninLogStyle);
    //       // console.log("%cSunucu Zamanı: "+new Date(response.data.currentDate), warninLogStyle);
    //       // console.log("New date time",new Date(browserDate.getTime() + ServerTimeDifference));
    //     }
    //   }).catch((e: any) => {})
}

getServerTime();

let offset = new Date().getTimezoneOffset() / 60;

export function ServerDate() {
    return new Date(new Date().getTime() + ServerTimeDifference);
}

export function useForceUpdate() {
    // @ts-ignore
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value: any) => value + 1); // update the state to force render
}

export function dateFormat(eventDate: string) {
    let date = new Date(eventDate);
    date = new Date(date.setTime(date.getTime() - offset));
    return date.toLocaleDateString();
}

export function dateTimeFormat(eventDate: string) {
    let date = new Date(eventDate);
    date = new Date(date.setTime(date.getTime() - offset));
    return date.toLocaleDateString() + " " + date.toLocaleTimeString().slice(0, 5);
}

export function showAgreementModal(key: any) {
    let content = agreements[key];
    if (content === undefined || key.startsWith("fp")) {
        content = flatPages[key.replace("fp", "")];
    }
    if (content) {
        modalServiceSubject.next({
            title: content.title,
            greenBg: true,
            innerHtml: true,
            content: content.content,
            width: 720,
            confirm: {
                cancel: {
                    label: "Kapat",
                },
            },
        });
    }
}

const monthNames: any = {
    "0": "Ocak",
    "1": "Şubat",
    "2": "Mart",
    "3": "Nisan",
    "4": "Mayıs",
    "5": "Haziran",
    "6": "Temmuz",
    "7": "Ağustos",
    "8": "Eylül",
    "9": "Ekim",
    "10": "Kasım",
    "11": "Aralık",
};

export const longDays: any = {
    0: "Pazar",
    1: "Pazartesi",
    2: "Salı",
    3: "Çarşamba",
    4: "Perşembe",
    5: "Cuma",
    6: "Cumartesi",
};
export const shortDays: any = {
    0: "Pzr",
    1: "Pts",
    2: "Sal",
    3: "Çar",
    4: "Per",
    5: "Cum",
    6: "Cmt",
};

export function getDateHumanize(eventDate: string, t: any = "long") {
    var now = ServerDate();
    var date1 = new Date(now.toISOString());
    var date2 = new Date(eventDate);

    var Difference_In_Days = date2.getDate() - date1.getDate();

    let day = "";
    if (Difference_In_Days === 0) {
        day = "Bugün";
    } else if (Difference_In_Days === 1) {
        day = "Yarın";
    } else if (Difference_In_Days > 6) {
        day = date2.getDate() + " " + monthNames[String(date2.getMonth())];
    } else {
        if (t === "long") {
            day = longDays[date2.getDay()];
        } else {
            day = shortDays[date2.getDay()];
        }
    }
    return day;
}

export function dateTimeStringFormat(eventDate: string, year: any = false) {
    let date = new Date(eventDate);
    return (
        date.getDate() + " " + date.toLocaleString("tr-tr", {month: "long"}) + (year ? " " + date.getFullYear() : "")
    );
}

export function backDrop(status: boolean) {
    if (status) {
        try {
            // @ts-ignore
            document.getElementById("backdrop-blur").style.display = "block";
        } catch (e) {
        }
    } else {
        try {
            // @ts-ignore
            document.getElementById("backdrop-blur").style.display = "none";
        } catch (e) {
        }
    }
}

export function exceptionModal(e: any, option: any = null) {
    let defaultOption: any = {
        title: "Beklenmedik Bir Hata!",
        innerHtml: true,
        content: `<p>${e}</p>`,
        timeout: 5000,
        confirm: {
            cancel: {
                label: "Tamam",
            },
        },
    };
    if (option) {
        for (const [k, v] of Object.entries(option)) {
            defaultOption[k] = v;
        }
    }
    modalServiceSubject.next(defaultOption);
}

export function CurrencyFormat(price: any, decimal: number = 2) {
    if (decimal === 0) {
        return Number(price)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&#")
            .slice(0, -3)
            .replaceAll("#", ".");
    }
    return toFixedRound(Number(price), Number(decimal))
        .replace(/\d(?=(\d{3})+\.)/g, "$&#")
        .replaceAll(".", ",")
        .replaceAll("#", ".");
}

export function toFixedRound(value: any, pow: any) {
    let i = String(Number(value)).split(".");
    if (pow === 0) {
        return i[0];
    }
    return i[0] + "." + (i.length === 1 ? "00000000000000000".slice(0, pow) : (i[1] + "00000000000000000").slice(0, pow));
}

export function copyMemberNumber(text: string, message: any = null) {
    navigator.clipboard.writeText(text).then(() => {
        toast.success(message ? message : "Kopyalandı!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    });
}

const {innerWidth: width} = window;
export let mobileView = width < 986 ? true : false;

export function getDateTimeHumanize(eventDate: string, t: any = "long") {
    var now = ServerDate();
    var date1 = new Date(now.toISOString());
    var date2 = new Date(eventDate);

    var Difference_In_Days = date2.getDate() - date1.getDate();
    let day = "";
    if (Difference_In_Days == 0) {
        day = "Bugün";
    } else if (Difference_In_Days == -1) {
        day = "Dün";
    } else if (Difference_In_Days == 1) {
        day = "Yarın";
    } else if (Difference_In_Days > 6) {
        day = date2.getDate() + " " + monthNames[String(date2.getMonth())];
    } else {
        if (t === "long") {
            day = longDays[date2.getDay()];
        } else {
            day = shortDays[date2.getDay()];
        }
    }
    return day + " " + date2.toLocaleTimeString().slice(0, 5);
}

export function loadingAnimation(state: any) {
    let loading: any = document.getElementById("id_loaderAnimation");
    if (loading) {
        if (state) {
            loading.style.display = "block";
        } else {
            loading.style.display = "none";
        }
    }
}

export function getTipsters() {
    //fixme prod a geçerken bu satır silinmeli
    Storage.del(`tipsters`);

    let cachedData = Storage.get(`tipsters`);
    if (!cachedData) {
        let api = new ApiService();
        api.start("get", env.api + "/member/api/tipsters/", null, false).then((res: any) => {
            Storage.set(`tipsters`, res.data, 1 * 60 * 1000);
            if (res.status) {
                res.data.forEach((tipster: any) => {
                    tipster.avatar = tipster.avatar ? env.api + tipster.avatar : null;
                    tipstersData[tipster.id] = tipster;
                });
            }
        });
    } else {
        cachedData.forEach((tipster: any) => {
            tipstersData[tipster.id] = tipster;
        });
    }
}

export async function getAgreements() {
    // fixme yeni kayıt eklendiğinde güncelleme için WS vs düşünülmeli
    // fixme prod ortama geçince bu satır iptal edilmeli
    Storage.del("agreements");
    let existingAgreements = Storage.get("agreements");
    if (existingAgreements) {
        for (let [k, v] of Object.entries(existingAgreements)) {
            agreements[k] = v;
        }
        agreementSubject.next({action: "loaded"});
        return agreements;
    }
    let api = new ApiService();
    api
        .start("get", env.api + "/misc/api/list-agreement/", null, false)
        .then((response: any) => {
            if (response.status) {
                for (let item of response.data) {
                    agreements[item.slug] = item;
                }
                Storage.set("agreements", agreements, 60 * 60 * 1000);
                agreementSubject.next({action: "loaded"});
            }
        })
        .catch((e: any) => {
            return null;
        })
        .finally(() => {
            return agreements;
        });
}

export async function getFlatPages() {
    // fixme yeni kayıt eklendiğinde güncelleme için WS vs düşünülmeli
    // fixme prod ortama geçince bu satır iptal edilmeli
    Storage.del("flatpages");
    let existingFlatPages = Storage.get("flatpages");
    if (existingFlatPages) {
        for (let [k, v] of Object.entries(existingFlatPages)) {
            flatPages[k] = v;
        }
        flatPageSubject.next({action: "loaded"});
        return flatPages;
    }

    let api = new ApiService();
    api
        .start("get", env.api + "/misc/api/list-flatpages/", null, false)
        .then((response: any) => {
            if (response.status) {
                for (let item of response.data) {
                    flatPages[item.slug] = item;
                }
                Storage.set("flatpages", flatPages, 60 * 1000);
                flatPageSubject.next({action: "loaded"});
            }
        })
        .catch((e: any) => {
            return null;
        })
        .finally(() => {
            return flatPages;
        });
}

export async function getNews() {
    // fixme yeni kayıt eklendiğinde güncelleme için WS vs düşünülmeli

    // let existingNews = Storage.get('news');
    // if (existingNews) {
    //     Object.assign(news, existingNews)
    //     newsSubject.next({action: 'loaded'})
    // }

    let api = new ApiService();
    api
        .start("get", env.api + "/misc/api/list-news/", null, false)
        .then((response: any) => {
            if (response.status) {
                // Object.assign(news, [])
                response.data.forEach((item: any) => {
                    let hasAdded = news.find((e: any) => e.id === item.id);
                    if (!hasAdded) {
                        news.push(item);
                    }
                });
                Storage.set("news", news, 24 * 60 * 60 * 1000);
                newsSubject.next({action: "loaded"});
            }
        })
        .catch((e: any) => {
        })
        .finally(() => {
        });
}

export function isOnline() {
    ConnectionStatus.isConnected = true;
    console.log("%c Internet connected up again :)", successLogStyle);
}

export function isOffline() {
    ConnectionStatus.isConnected = false;
    console.log("%c Ops, Connection lost ! :(", errorLogStyle);
    if (wsProvider && wsProvider.wsHandler && wsProvider.wsHandler.readyState === 1) {
        wsProvider.wsHandler.close();
    }
}

export function getTodayResults() {
    let startDate = new Date();
    let api = new ApiService();
    api
        .start(
            "get",
            `${resultsUrlPrefix}/${String(startDate.getDate()).padStart(2, "0")}${String(startDate.getMonth() + 1).padStart(
                2,
                "0"
            )}${startDate.getFullYear()}/`,
            null,
            true
        )
        .then((response: any) => {
            if (response.status) {
                response.data.forEach((result: any) => {
                    result.runsMap = [];
                    result.runs.forEach((run: any) => {
                        result.runsMap[run.no] = run;
                        if (run.horses != null) {
                            run.winnerHorse = run.horses.filter((el: any) => el.result != null && el.result.toString() === "1")[0];
                            if (run.winnerHorse.stablemate > 0) {
                                run.winnerStablemate = run.horses.filter(
                                    (e: any) => Number(e.stablemate) === Number(run.winnerHorse.stablemate)
                                );
                            }
                        }
                    });
                    todayResults[result.keyword] = result;
                    todayResultsSubject.next(true);
                });
            }
        });
    if (resultsInterval == null) {
        resultsInterval = setInterval(getTodayResults, 300 * 1000);
    }
}

export const preventBetSlipValues = (e: any) => ["e", "E", "+", "-", ",", "."].includes(e.key) && e.preventDefault();
export const preventNegativeValues = (e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

const dateDifferenceInHours = (dateInitial: any, dateFinal: any) => (dateFinal - dateInitial) / 3_600_000;

export function showProfessionModalWss(timeout: any = 2000) {

    let optionValues = '<option value="DEFAULT">Seç</option>';
    for (const profession of professions) {
        optionValues += `<option value=${profession.id} key=${profession.name}>${profession.name}</option>`
    }
    setTimeout(() => {
        modalServiceSubject.next({
            closeIcon: false,
            greenBg:true,
            cancelBackdrop: true,
            innerHtml: true,
            title: 'Meslek Bilginizi Seçin',
            content: `<p class="text-center mb-2 mt-2">Yasal gereklilikler nedeniyle iş ve meslek bilginizi kaydetmeniz gerekmektedir. Lütfen iş ve meslek bilginizi seçiniz</p><p class="text-center mb-2">Aydınlatma metnine <a class="text-primary fw-medium text-decoration-underline" id="agreement-btn" href="#">buradan</a> ulaşabilirsiniz.</p><div><select class="form-select register-select mt-2 w-100" value="DEFAULT" id="professionSelect">${optionValues}</select></div>`,
            confirm: {
                sure: {
                    label: 'Kaydet',
                    action: () => {
                        // @ts-ignore
                        var profession: any = document.getElementById('professionSelect').value;
                        if (profession != null || profession != undefined || profession != 'DEFAULT') {
                            const apiService = new ApiService();
                            apiService.start('post', env.api + '/member/api/update-account-info/', {
                                token: Storage.get('token'),
                                profession: profession
                            }, true)
                                .then((response: any) => {
                                    if (response.status) {

                                    }
                                }).catch((e: any) => {
                                console.log(e)
                            })
                        }
                    }
                },

            },
            afterLoad: () => {
                // @ts-ignore
                document.getElementById('id_btnModelServiceSure').setAttribute('disabled', 'true');
                // @ts-ignore
                document.getElementById('id_btnModelServiceSure').classList.add('disabled');
                // @ts-ignore
                document.getElementById('id_btnModelServiceSure').classList.remove('secondary');
                // @ts-ignore
                document.getElementById('id_btnModelServiceSure').setAttribute('disabled', 'true')
                // @ts-ignore
                var professionSelect: any = document.getElementById('professionSelect');
                professionSelect.addEventListener('change', (event: any) => {
                    if (event.target.value !== 'DEFAULT') {
                        // @ts-ignore
                        document.getElementById('id_btnModelServiceSure').classList.add('secondary');
                        // @ts-ignore
                        document.getElementById('id_btnModelServiceSure').classList.remove('disabled');
                        // @ts-ignore
                        document.getElementById('id_btnModelServiceSure').removeAttribute('disabled')
                    } else {
                        // @ts-ignore
                        document.getElementById('id_btnModelServiceSure').classList.add('disabled');
                        // @ts-ignore
                        document.getElementById('id_btnModelServiceSure').classList.remove('secondary');
                        // @ts-ignore
                        document.getElementById('id_btnModelServiceSure').setAttribute('disabled', 'true')
                    }
                });

                var agreementBtn: any = document.getElementById('agreement-btn');
                agreementBtn.addEventListener('click', (event: any) => {
                    let content = agreements['aydinlatma'];

                    if (content) {
                        modalServiceSubject.next({
                            title: content.title,
                            greenBg: true,
                            innerHtml: true,
                            cancelBackdrop: true,
                            content: content.content,
                            width: 720,
                            confirm: {
                                cancel: {
                                    label: 'Tamam', action: () => {
                                        showProfessionModalWss(timeout = 0);
                                    }
                                },
                            },
                        });
                    }


                })


            },
            timeout: 0
        });
    }, timeout);

}
