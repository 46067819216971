import exp from "node:constants";

export let warningLogStyle = "color: black; background: yellow; font-size: 12px; font-weight:bold";
export let successLogStyle = "color: white; background: green; font-size: 12px; font-weight:bold";
export let errorLogStyle = "color: white; background: red; font-size: 12px; font-weight:bold";

export const customStyles = {
  overlay: {
    background: "rgba(0,0,0,0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "calc(100vw - 80px)",
    width: "460px",
    padding: "1.5rem",
    maxHeight: "calc(100vh - 100px)",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    borderRadius: "15px",
    border: "0",
  },
};

export const cities = [
  "İkamet edilen il",
  "İSTANBUL",
  "ANKARA",
  "İZMİR",
  "BURSA",
  "ADANA",
  "ADIYAMAN",
  "AFYONKARAHİSAR",
  "AĞRI",
  "AKSARAY",
  "AMASYA",
  "ANTALYA",
  "ARDAHAN",
  "ARTVİN",
  "AYDIN",
  "BALIKESİR",
  "BARTIN",
  "BATMAN",
  "BAYBURT",
  "BİLECİK",
  "BİNGÖL",
  "BİTLİS",
  "BOLU",
  "BURDUR",
  "ÇANAKKALE",
  "ÇANKIRI",
  "ÇORUM",
  "DENİZLİ",
  "DİYARBAKIR",
  "KOCAELİ",
  "KONYA",
  "KÜTAHYA",
  "MALATYA",
  "MANİSA",
  "MARDİN",
  "MERSİN",
  "MUĞLA",
  "MUŞ",
  "NEVŞEHİR",
  "NİĞDE",
  "ORDU",
  "OSMANİYE",
  "RİZE",
  "SAKARYA",
  "SAMSUN",
  "SİİRT",
  "SİNOP",
  "ŞIRNAK",
  "SİVAS",
  "TEKİRDAĞ",
  "TOKAT",
  "TRABZON",
  "TUNCELİ",
  "ŞANLIURFA",
  "UŞAK",
  "VAN",
  "YALOVA",
  "YOZGAT",
  "ZONGULDAK",
  "DÜZCE",
  "EDİRNE",
  "ELAZIĞ",
  "ERZİNCAN",
  "ERZURUM",
  "ESKİŞEHİR",
  "GAZİANTEP",
  "GİRESUN",
  "GÜMÜŞHANE",
  "HAKKARİ",
  "HATAY",
  "IĞDIR",
  "ISPARTA",
  "KAHRAMANMARAŞ",
  "KARABÜK",
  "KARAMAN",
  "KARS",
  "KASTAMONU",
  "KAYSERİ",
  "KİLİS",
  "KIRIKKALE",
  "KIRKLARELİ",
  "KIRŞEHİR",
];

export const professions = [
  { id: 0, name: "Akaryakıt İstasyonu Sahibi /İşletmecisi" },
  { id: 1, name: "Aktör/Aktrist/Yönetmen" },
  { id: 2, name: "Antikacı" },
  { id: 3, name: "Askeri Personel" },
  { id: 4, name: "Askeri Personel - Albay / Yarbay" },
  { id: 5, name: "Askeri Personel - General / Amiral" },
  { id: 6, name: "Aşçı" },
  { id: 7, name: "At Yetiştiricisi/Sahibi" },
  { id: 8, name: "Belediye Başkanı / Belediye Başkan Yardımcısı" },
  { id: 9, name: "Berber/Kuaför/Güzellik Uzmanı" },
  { id: 10, name: "Bilim İnsanı" },
  { id: 11, name: "Boyacı / Elektrikçi / Tesisatçı / Tamirci / Marangoz" },
  { id: 12, name: "Çalışmıyor" },
  { id: 13, name: "Çevirmen/Tercüman/Mütercim" },
  { id: 14, name: "Çifçti" },
  { id: 15, name: "Danışman" },
  { id: 16, name: "Denetçi / Müfettiş" },
  { id: 17, name: "Din Görevlisi" },
  { id: 18, name: "Diplomat / Bürokrat / Konsolos / Büyükelçi" },
  { id: 19, name: "Distribütör" },
  { id: 20, name: "Diyetisyen" },
  { id: 21, name: "Döviz Bürosu Sahibi" },
  { id: 22, name: "Ekonomist" },
  { id: 23, name: "Emekli" },
  { id: 24, name: "Emlak Komisyoncusu" },
  { id: 25, name: "Emniyet Görevlisi/Polis/Bekçi" },
  { id: 26, name: "Esnaf / İş Yeri Sahibi" },
  { id: 27, name: "Ev Hanımı" },
  { id: 28, name: "Finans Çalışanı / Bankacı" },
  { id: 29, name: "Finans Çalışanı / Borsacı/ Dealer / Broker" },
  { id: 30, name: "Firma Ortağı/Sahibi" },
  { id: 31, name: "Futbolcu" },
  { id: 32, name: "Galerici" },
  { id: 33, name: "Garson/Barmen" },
  { id: 34, name: "Gümrük Müşaviri/Çalışanı" },
  { id: 35, name: "Güvenlik Görevlisi" },
  { id: 36, name: "Hakem" },
  { id: 37, name: "Hemşire" },
  { id: 38, name: "Hizmet Görevlisi/Çalışanı" },
  { id: 39, name: "Hostes/Host" },
  { id: 40, name: "Hukuk Bürosu Sahibi" },
  { id: 41, name: "Hukuk Çalışanı - Avukat" },
  { id: 42, name: "Hukuk Çalışanı - Hakim / Savcı" },
  { id: 43, name: "Hukuk Müşaviri / Danışmanı" },
  { id: 44, name: "İnfluncer/Youtuber/Gamer" },
  { id: 45, name: "İstatistikçi, Kimyager, Matematikçi, Fizikçi" },
  { id: 46, name: "İşçi" },
  { id: 47, name: "İşletmeci" },
  { id: 48, name: "İtfaiyeci" },
  { id: 49, name: "Jokey" },
  { id: 50, name: "Kurye" },
  { id: 51, name: "Kuyumcu/Sarraf" },
  { id: 52, name: "Mali Müşavir / Muhasebeci" },
  { id: 53, name: "Manajer / Yapımcı / Yönetmen / Organizatör" },
  { id: 54, name: "Manken/Model" },
  { id: 55, name: "Medya (Genel Yayın Yönetmeni, İmtiyaz Sahibi Kişi)" },
  {
    id: 56,
    name: "Medya Çalışanı (Gazeteci, Yazar, Editör, Basın Mensubu, Sunucu, Spiker, Muhabir, Kameraman vb.)",
  },
  { id: 57, name: "Medya Kuruluşu Sahibi, Ortağı, Yönetim Kurulu Üyesi" },
  { id: 58, name: "Memur" },
  { id: 59, name: "Milletvekili / Bakan / Siyasi Parti Başkanı / Siyasi Parti Başkan Yardımcısı" },
  { id: 60, name: "Modelist/Stilist" },
  { id: 61, name: "Muhtar" },
  { id: 62, name: "Mühendis / Mimar / İç Mimar / Dekoratör" },
  { id: 63, name: "Müsteşar" },
  { id: 64, name: "Müteahhit" },
  { id: 65, name: "Nakliyeci" },
  { id: 66, name: "Noter" },
  { id: 67, name: "Orta Düzey Yönetici (Kamu)" },
  { id: 68, name: "Orta Düzey Yönetici (Özel Sektör)" },
  { id: 69, name: "Otel / Pansiyon / Restoran Sahibi - Ortağı" },
  { id: 70, name: "Otel/Pansiyon/Restoran Yöneticisi" },
  { id: 71, name: "Öğrenci" },
  { id: 72, name: "Öğretmen/Akademisyen" },
  { id: 73, name: "Pilot / Kaptan" },
  { id: 74, name: "Psikolog" },
  { id: 75, name: "Rehber/Tur Operatörü" },
  { id: 76, name: "Reklam Ve Halkla İlişkiler (Reklamcı)" },
  { id: 77, name: "Sağlık - Eczacı" },
  { id: 78, name: "Sağlık - Tıp Doktoru / Diş Hekimi" },
  { id: 79, name: "Sağlık Görevlisi" },
  { id: 80, name: "Sanatçı / Yazar / Müzisyen / Ressam / Fotoğrafçı / Tasarımcı" },
  { id: 81, name: "Satış / Pazarlama Temsilcisi" },
  { id: 82, name: "Sekreter/Yönetici Asistanı" },
  { id: 83, name: "Sigortacı" },
  { id: 84, name: "Sporcu/Menajer" },
  { id: 85, name: "Şef / Uzman / Yetkili (Özel Sektör)" },
  { id: 86, name: "Şoför" },
  { id: 87, name: "Teknik Direktör/Antrenör" },
  { id: 88, name: "Teknik Görevli/Teknisyen" },
  { id: 89, name: "Terzi" },
  { id: 90, name: "Tezgahtar/Kasiyer" },
  { id: 91, name: "Turizm Acentası/Çalışanı" },
  { id: 92, name: "Tüccar" },
  { id: 93, name: "Üst Düzey Emniyet Mensubu / Başkomiser / Komiser vb." },
  { id: 94, name: "Üst Düzey Yönetici (Kamu)" },
  { id: 95, name: "Üst Düzey Yönetici (Özel Sektör)" },
  { id: 96, name: "Vali / Kaymakam" },
  { id: 97, name: "Veteriner" },
  { id: 98, name: "Veznedar/Tahsildar" },
  { id: 99, name: "Yaşam Koçu" },
  { id: 100, name: "Yazılımcı/Analist" },
  { id: 101, name: "Yeminli Mali Müşavir" },
];

export const pageMetaTags = {
  "": {
    title: "Bitrelic Cüzdan",
    description:
      "Türkiye'nin Elektronik Parası Bitrelic Cüzdan ile tanışın. Hızlı, güvenli ve kolay ödeme yapın, para gönderin, alın ve saklayın.",
    keywords:
      "Türkiye'nin Elektronik Parası Bitrelic Cüzdan ile tanışın. Hızlı, güvenli ve kolay ödeme yapın, para gönderin, alın ve saklayın.",
  },
  login: {
    title: "Cüzdan Hesabına Giriş Yapın",
    description:
      "Türkiye'nin Elektronik Parası Bitrelic Cüzdan ile tanışın. Hızlı, güvenli ve kolay ödeme yapın, para gönderin, alın ve saklayın.",
    keywords:
      "Türkiye'nin Elektronik Parası Bitrelic Cüzdan ile tanışın. Hızlı, güvenli ve kolay ödeme yapın, para gönderin, alın ve saklayın.",
  },
};

export const passwordModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxWidth: "calc(100vw - 80px)",
    width: "460px",
    padding: "1.5rem",
    maxHeight: "calc(100vh - 100px)",
    transform: "translate(-50%, -50%)",
    background: "var(--bs-white)",
  },
};

export const monthMapping: any = {
  0: { name: "Ocak", shortName: "Oca" },
  1: { name: "Şubat", shortName: "Şub" },
  2: { name: "Mart", shortName: "Mar" },
  3: { name: "Nisan", shortName: "Nis" },
  4: { name: "Mayıs", shortName: "May" },
  5: { name: "Haziran", shortName: "Haz" },
  6: { name: "Temmuz", shortName: "Tem" },
  7: { name: "Ağustos", shortName: "Ağu" },
  8: { name: "Eylül", shortName: "Eyl" },
  9: { name: "Ekim", shortName: "Eki" },
  10: { name: "Kasım", shortName: "Kas" },
  11: { name: "Aralık", shortName: "Ara" },
};

export const passwordSpecialCharacters = "[~!@#$%^&*_=`|(){}[]:;',.?]*$/";
