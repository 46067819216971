import React, {useEffect, useState} from "react";
import {useForceUpdate} from "../../services/misc.functions";
import {useParams} from "react-router-dom";
import paths from "../../paths";

const tabs = [
    {value: 0, label: "TJK TV"},
    {value: 1, label: "TJK TV (Yurt Dışı)"},
];


let width: any = 0;

export function Stream() {

    const params = useParams();

    const [selectedTab, setSelectedTab] = useState<any>(params?.tab ? Number(params?.tab) : 0);
    const forceUpdate = useForceUpdate();


    useEffect(() => {

        videoWrapper = document.getElementById("id_video_wrapper");
        if (videoWrapper) {
            width = videoWrapper.offsetWidth;
            forceUpdate();
        }

        window.addEventListener('resize', resizeWindow);
        return () => {
            window.removeEventListener('resize', resizeWindow);
        };
    }, []);

    const resizeWindow = () => {
        if (width !== window.innerWidth) {
            if (videoWrapper) {
                width = videoWrapper.offsetWidth;
            }
            forceUpdate();
        }
    }


    let videoWrapper = document.getElementById("id_video_wrapper");
    if (videoWrapper) {
        console.log(videoWrapper.offsetWidth);
    }


    return (<>
        <div className="stream-wrapper">
            <div className="container ">
                <div className="stream-pills">
                    <div className={`stream-pill ${selectedTab === 0 ? 'active' : ''}`} onClick={() => {
                        setSelectedTab(0)
                    }}>
                        <span className={`text-xsmall text-bold ${selectedTab === 0 ? 'text-primary' : 'text-white'}`}>Yurt İçi Yayın</span>
                    </div>
                    <div className={`stream-pill right ${selectedTab === 1 ? 'active' : ''}`} onClick={() => {
                        setSelectedTab(1)
                    }}>
                        <span className={`text-xsmall text-bold ${selectedTab === 1 ? 'text-primary' : 'text-white'}`}>Yurt Dışı Yayın</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="container ">
            <div className="mt-3 mb-5 stream-video-wrapper" id={"id_video_wrapper"}>
                <p className="text-lg text-primary text-bolder mb-3">
                    {selectedTab === 0 ?
                        'TJK TV Canlı Yayın' : 'TJK TV Yurt Dışı Canlı Yayın'}
                </p>
                {selectedTab === 0 ?
                    <iframe width={width} height={width * 315 / 560}
                            src="https://www.youtube.com/embed/g89RQMJtK6E?si=J7XW7PSxljzq0C64?controls=1&showinfo=0&rel=0&autoplay=1&loop=1&mute=1"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    : <iframe width={width} height={width * 315 / 560}
                              src="https://www.youtube.com/embed/ri4rR0H2i9c?controls=1&showinfo=0&rel=0&autoplay=1&loop=1&mute=1"
                              title="YouTube video player" frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen></iframe>}
            </div>
        </div>
    </>)
}
